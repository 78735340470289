.one__tour {
  .section__subscribe {
    display: none;
  }
}

.section__tour {
  padding-top: 15px;
  padding-bottom: 88px;
}

.tour__tabs {
  justify-content: space-around;
  margin-left: 50px;
  margin-right: 50px;
  border-bottom: 3px solid #00b6b4;

  a {
    display: block;
    line-height: 57px;
    color: #000000;
    font-size: 19px;
    padding-left: 30px;
    padding-right: 30px;
    transition: .3s;

    &:hover {
      color: #00b6b4;
    }
    &.active {
      color: #00b6b4;
    }
  }
}

.tour__title {
  margin-top: 66px;
}

.tour__title {
  font-size: 25px;
  line-height: 26px;
  color: #000;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
}

.section__tour__content {
  justify-content: space-between;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 66px;
  padding-bottom: 120px;
}

.tour__img {
  width: 570px;

  img {
    display: block;
    width: 100%;
  }
}

.tour__tabs-target {
  width: 335px;
  padding-right: 30px;
}

.tour__tab {
  color: #333232;
  font-size: 18px;
  line-height: 30px;
  display: none;
}

#tab1 {
  display: block;
}


.tour__form {
  display: block;
  margin: 0 auto;
  width: 462px;
  background-color: #00b6b4;
  padding-top: 31px;
  padding-bottom: 15px;
  position: relative;

  .page__title {
    width: 383px;
    margin: 0 auto;
    background-image: url(../img/decor/form_bg.png);
  }

  form {
    display: block;
    width: 295px;
    margin: 0 auto;
    margin-top: 18px;

    .form__submit {
      margin-top: 0;
    }

    input[type="text"]::-webkit-input-placeholder { color: #010101; }
    input[type="text"]:-ms-input-placeholder { color: #010101; }
    input[type="text"]::-ms-input-placeholder { color: #010101; }
    input[type="text"]::placeholder { color: #010101; }

    input[type="email"]::-webkit-input-placeholder { color: #010101; }
    input[type="email"]:-ms-input-placeholder { color: #010101; }
    input[type="email"]::-ms-input-placeholder { color: #010101; }
    input[type="email"]::placeholder { color: #010101; }

    input[type="tel"]::-webkit-input-placeholder { color: #010101; }
    input[type="tel"]:-ms-input-placeholder { color: #010101; }
    input[type="tel"]::-ms-input-placeholder { color: #010101; }
    input[type="tel"]::placeholder { color: #010101; }
  }
}

.tour__form__undertitle {
  font-size: 25px;
  color: #fff;
  text-align: center;
  margin-top: 10px;
}


@media screen and (max-width: 1160px) {
  .section__tour__content {
    padding-left: 70px;
    padding-right: 70px;
  }
}

@media screen and (max-width: 1100px) {
  .section__tour__content {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (max-width: 1024px) {
  .tour__tabs {
    margin-left: 15px;
    margin-right: 15px;
  }
  .section__tour__content {
    padding-left: 15px;
    padding-right: 15px;
  }
  .tour__img {
    width: calc(50% - 15px);
  }
  .tour__tabs-target {
    width: calc(50% - 15px);
  }
  .section__tour__content {
    padding-bottom: 70px;
  }
}


@media screen and (max-width: 760px) {
  .section__tour {
    padding-top: 0;
    padding-bottom: 40px;
  }
  .tour__tabs {
    display: block;
    border: none;

    a {
      line-height: 50px;
      padding: 0 !important;
    }
  }
  .tour__title {
    margin-top: 35px;
  }
  .tour__img {
    width: 100%;
  }
  .section__tour__content {
    padding-top: 50px;
  }
  .tour__tabs-target {
    width: 100%;
    margin-top: 40px;
    padding-right: 137px;
  }
  .one__tour {
    .section__subscribe {
      display: block;
    }
  }
  .tour__form {
    max-width: 492px;
    width: calc(100% - 30px);
    position: relative;

    .top__page {
      display: block;
      top: -100px;
      right: 25px;

      img {
        width: 34px;
      }
    }
  }
}


@media screen and (max-width: 450px) {
  .tour__tabs-target {
    padding-right: 50px;
  }
  .tour__form {
    .page__title {
      width: 302px;
      background-image: url(../img/decor/title_bg.png);
    }

    form {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

@media screen and (max-width: 359px) {
  .tour__form {
    width: 100%;
    max-width: 100%;
  }
}