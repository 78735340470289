.section__catefories {
  padding-top: 66px;
  padding-bottom: 36px;
}

.section__catefories__content {
  padding-left: 44px;
  padding-right: 44px;
}

.catefories__list {
  justify-content: space-between;
  margin-top: 45px;
}

.catefories__item {
  width: 317px;
  height: 326px;
}

.catefories__link {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  &:hover {
    .catefories__name {
      color: #ff7f00;
    }
  }
}

.catefories__name {
  position: absolute;
  left: 0;
  right: 0;
  top: 25px;
  height: 92px;
  align-items: center;
  align-content: center;
  text-align: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: rgb(255, 255, 255);
  opacity: 0.71;
  font-size: 25px;
  color: #000;
  font-weight: 600;
  text-transform: uppercase;
  transition: .3s;
}

.catefories__bottom {
  justify-content: flex-end;
  margin-top: 27px;
}

.other__pages {
  .section__catefories {
    padding-top: 55px;
    padding-bottom: 12px;
  }
  .catefories__item {
    margin-bottom: 36px;
  }
  .page__title {
    margin-bottom: 0 !important;
  }
}


@media screen and (max-width: 1200px) {
  .section__catefories__content {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 1023px) {
  .catefories__list  {
    justify-content: center;
  }
  .catefories__item {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 40px;
  }
  .section__last .arrow__down {
    bottom: 28px;
  }
  .catefories__bottom {
    margin-top: 4px;
  }
}


@media screen and (max-width: 499px) {
  .section__catefories {
    padding-top: 40px;
  }
  .catefories__list {
    margin-top: 32px;
  }
  .catefories__item {
    margin-bottom: 23px;
    margin-left: 0;
    margin-right: 0;
  }
  .other__pages {
    .section__catefories {
      padding-bottom: 10px;
      padding-top: 0;
    }
  }
  .catefories__list {
    margin-top: 46px;
  }
}