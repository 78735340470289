.section__quote {
  background-image: url(../img/backgrounds/quote_bg.jpg);
  -webkit-background-size: cover;
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  padding-top: 200px;
  padding-bottom: 230px;
}

.quote__text {
  text-align: center;
  font-size: 35px;
  color: #fff;
  line-height: 42px;
  width: 730px;
  font-family: "MuseoSansCyrl900";
  text-transform: uppercase;
  letter-spacing: 4px;
}

@media screen and (max-width: 1000px) {
  .quote__text {
    font-size: 28px;
    width: 600px;
    line-height: 42px;
  }
}

@media screen and (max-width: 760px) {
  .section__quote {
    display: none;
  }
}