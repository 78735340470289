.section__main {
  background-image: url(../img/backgrounds/main__bg.jpg);
  -webkit-background-size: cover;
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  height: 826px;
  background-color: #e0f5f5;
}

.section__main__content {
  justify-content: center;
  padding-top: 31px;
}

.main__logo {
  width: 342px;
  text-align: center;
  span {
    width: 100%;
    display: block;
    font-size: 18px;
    color: #1e1e1e;
    font-family: "MontserratRegular";
    letter-spacing: 6px;;
    margin-top: 12px;
  }
}

.section__main__nav {
  width: 100%;
  justify-content: center;
  height: 53px;
  margin-top: 35px;
  background-image: url(../../img/decor/nav_bg.png);
  background-repeat: no-repeat;
  background-position: center center;

  li {
    margin-right: 74px;

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    font-size: 21px;
    color: #fff;
    line-height: 53px;
    transition: .3s;

    &:hover {
      color: #434141;
    }
  }
}

.pages__tree {
  width: 100%;
  max-width: 955px;
  margin-top: 25px;
  li {
    &:last-child {
      span {
        display: none;
      }
    }
  }
  a {
    color: #00b6b4;
    font-size: 19px;
    transition: .3s;
    
    &:hover {
      color: #007574;
    }
  }
}


@media screen and (max-width: 1200px) {
  .section__main {
    height: 733px;
  }
}

@media screen and (max-width: 1024px) {
  .section__main__nav li {
    margin-right: 30px;
  }
}

@media screen and (max-width: 760px) {
  .section__main__nav {
    display: none;
  }
  .section__main__content {
    padding-top: 45px;
  }
}

@media screen and (max-width: 500px) {
  .main__logo span {
    letter-spacing: 4px;
  }
  .section__main {
    height: 500px;
  }
}