.footer {
  background-color: #00b6b4;
}

.footer__container {
  padding-top: 38px;
  position: relative;
}

.top__page {
  position: absolute;
  right: 5px;
  top: -73px;
}

.footer__content {
  justify-content: space-between;
}

.footer__side {
  width: 348px;
  padding-bottom: 38px;
}

.footer__nav {
  margin-left: 100px;

  li {
    margin-bottom: 18px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    color: #fff;
    font-size: 19px;
    transition: .3s;

    &:hover {
      color: #424141;
    }
  }
}


.footer__form {
  width: 100%;
}

.footer__form__title {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  text-align: center;
  letter-spacing: 2px;
  margin-bottom: 17px;
}

.input__footer {
  width: 100%;
  height: 41px;
  margin-bottom: 19px;
}

.textarea__footer {
  width: 100%;
  height: 93px;
}

.submit__footer {
  padding: 0;
  background-image: none !important;
  display: block;
  margin: 0 auto;
  font-size: 20px;
  text-decoration: underline !important;
  margin-top: 12px;
}


.footer__center {
  text-align: center;
  justify-content: center;
  width: 300px;
  align-items: flex-start;
  align-content: flex-start;
  padding-bottom: 38px;
}

.footer__logo {
  display: inline-block;
}

.footer__emeil {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 31px;
  letter-spacing: 1px;
  transition: .3s;

  &:hover {
    color: #424141;
  }
}

.footer__socials {
  align-items: center;
  align-content: center;
  width: 98px;
  justify-content: space-between;
  margin-top: 55px;

  a {
    &:hover {
      img {
        -webkit-filter: invert(60%);
        filter: invert(60%);
      }
    }
  }

  img {
    max-width: 29px;
    max-height: 25px;
    transition: .3s;
  }
}

.copyright {
  height: 70px;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: #000;
  font-size: 17px;
  background-color: #fff;
}


@media screen and (max-width: 1200px) {
  .footer__container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .footer__side {
    width: auto;
    max-width: 290px;
  }
  .footer__nav {
    margin-left: 0;
  }
}

@media screen and (max-width: 900px) {
  .footer__side {
    width: 100%;
    max-width: inherit;
    &:first-child {
      display: none;
    }
  }
  .footer__center {
    width: 100%;
    order: 2;
  }
}

@media screen and (max-width: 500px) {
  .footer__container {
    padding-top: 15px;
  }
}