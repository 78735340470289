.section__walcome {
  padding-top: 58px;
  background-image: url(../img/backgrounds/welcome_bg.jpg);
  background-position: top center;
  background-repeat: repeat-x;
  text-align: center;
  position: relative;
  padding-bottom: 83px;

  .arrow__down {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
  }
}

.walcome__text {
  display: block;
  margin: 0 auto;
  max-width: 810px;
  text-align: center;
  font-size: 18px;
  line-height: 30px;
  margin-top: 30px;
}


@media screen and (max-width: 1200px) {
  .section__walcome {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 500px) {
  .section__walcome {
    padding-top: 40px;
    padding-bottom: 73px;
  }
  .section__title.bigBlie {
    font-size: 27px;
  }
  .walcome__text {
    font-size: 14px;
    line-height: 20px;
    margin-top: 18px;
  }
}