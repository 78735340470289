.article {
  background-color: #fff;
  
  .section__main {
    background-color: #fff;
  }
}

.section__article {
  padding-bottom: 49px;
}

.article__inform {
  padding-left: 30px;
  padding-right: 30px;
  justify-content: flex-end;
}

.article__inform__middle {
  align-items: center;
}

.article__author {
  width: 100%;
  color: #000;
  font-size: 20px;
  line-height: 1;
  margin-bottom: 3px;
  font-weight: 600;
}

.article__views {
  font-size: 18px;
  color: #000;
  margin-right: 16px;
  display: flex;
  align-items: center;
  align-content: center;

  img {
    margin-right: 5px;
  }
}
.article__comments_count {
  font-size: 18px;
  color: #000;
  display: flex;
  align-items: center;
  align-content: center;

  img {
    margin-right: 5px;
  }
}

.article__date {
  width: 100%;
  font-size: 18px;
  color: #000;
  margin-top: 3px;
}



.article__title {
  font-size: 25px;
  line-height: 20px;
  text-align: center;
  width: 100%;
  color: #000;
  margin-top: 20px;
}


.article__body {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  margin-top: 50px;
  color: #333232;
  font-size: 18px;
  text-align: left;
  padding-bottom: 60px;

  p {
    color: #333232;
    font-size: 18px;
    line-height: 30px;
    padding-bottom: 20px;
    padding-top: 20px;

    &:first-child {
      padding-top: 0;
    }
  }
  figure {
    margin: 0;

    img {
      width: auto;
    }
  }
  blockquote {
    position: relative;
    margin: 0;
    background-color: #e0f5f5;
    padding-top: 15px;
    padding-bottom: 15px;

    p {
      padding-bottom: 0;
      padding-top: 0;
    }

    &::before {
      content: '';
      position: absolute;
      width: 50vw;
      left: -50vw;
      top: 0;
      bottom: 0;
      background-color: #e0f5f5;
      background-image: url(../img/icons/quote.png);
      background-repeat: no-repeat;
      background-position: calc(100% - 34px) 22px;
    }
    &::after {
      content: '';
      position: absolute;
      width: 50vw;
      right: -50vw;
      top: 0;
      bottom: 0;
      background-color: #e0f5f5;
    }
  }

  ul {
    padding-top: 46px;
    padding-bottom: 46px;
    li {
      color: #000000;
      font-size: 18px;
      position: relative;
      padding-left: 32px;
      line-height: 26px;
      margin-bottom: 38px;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        content: '';
        position: absolute;
        width: 23px;
        height: 26px;
        background-image: url(../img/icons/item.png);
        background-repeat: no-repeat;
        top: 50%;
        transform: translateY(-50%);
        left: -26px;
      }
    }
  }

  h1, h2, h3, h4, h5 {
    margin: 0;
    padding: 0;
    font-size: 20px !important;
    font-weight: 600;
    padding-top: 10px;
  }

  em {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 16px;
      top: 0;
      bottom: 0;
      left: -30px;
      background-color: #ff8800;
    }
  }

  .wp-block-media-text {
    display: inline-block;
    position: relative;
    margin-top: 1px;
    margin-bottom: 1px;
    p {
      padding: 0 !important;
      margin: 0;
      font-size: 20px !important;
      font-weight: 600;
      color: #000;
      line-height: 46px;
    }
    div {
      padding: 0 !important;
      margin: 0 !important;
    }
    img {
      position: absolute;
      top: 50%;
      width: auto;
      transform: translateY(-50%);
      right: -137px;
      max-height: 46px;
    }
  }

  .wp-block-columns {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    p {
      padding: 0 !important;
    }
  }
  .has-2-columns {
    .wp-block-column {
      width: calc(50% - 40px);
    }
  }

  .colored {
    position: relative;
    background-color: #e0f5f5;

    &::before {
      content: '';
      position: absolute;
      width: 50vw;
      left: -50vw;
      top: 0;
      bottom: 0;
      background-color: #e0f5f5;
    }
    &::after {
      content: '';
      position: absolute;
      width: 50vw;
      right: -50vw;
      top: 0;
      bottom: 0;
      background-color: #e0f5f5;
    }
  }
}


.shere__block {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  align-content: center;
}

.shere__text {
  font-size: 20px;
  color: #000;
  font-weight: 600;
  margin-right: 52px;
}

.hhere__links {
  li {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }
}



@media screen and (max-width: 870px) {
  .section__article {
    padding-bottom: 30px;
  }
  .article__inform {
    padding-left: 15px;
    padding-right: 15px;
  }
  .article__inform {
    justify-content: flex-start;
  }

  .article__body {
    width: 100%;
    max-width: inherit;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 20px;
  }
  .article__body {
    font-size: 16px;
    padding-bottom: 20px;
    p {
      font-size: 16px;
      line-height: 20px;
    }
    blockquote {
      padding-left: 48px;
      &::before {
        left: calc(-50vw + 29px);
        background-position: 100% 18px;
        -webkit-background-size: 29px;
        background-size: 29px;
      }
    }

    ul {
      padding-top: 19px;
      padding-bottom: 36px;
      li {
        font-size: 16px;
        padding-left: 72px;
        line-height: 26px;
        &::before {
          left: 8px;
        }
      }
    }
    em {
      padding-left: 12px;
      display: block;
      &::before {
        left: -10px;
      }
    }
    .wp-block-media-text {
      img {
        right: -72px;
        max-width: 60px;
      }
    }
    .has-2-columns {
      .wp-block-column {
        width: calc(50% - 10px);
      }
    }
  }
  .shere__block {
    max-width: inherit;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .article__body figure img {
    max-width: 100%;
  }

  .shere__block {
    justify-content: center;
  }
  .shere__text {
    width: 100%;
    margin-right: 0;
    margin-bottom: 25px;
    text-align: center;
  }
}


@media screen and (max-width: 470px) {
  .article__body .wp-block-columns {
    flex-wrap: wrap;
  }
  .article__body .has-2-columns .wp-block-column {
    width: 100% !important;
    margin-bottom: 20px;
  }
}