.comment-author.says, .comment-author.avatar {
  display: none;
}

.edit-link {
  display: none;
}

.comment-list {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0;
  margin-top: 30px;
}

.comment {
  max-width: 914px;
  width: 100%;
  background-color: #e1f5f5;
  padding-left: 13px;
  padding-top: 10px;
  padding-right: 40px;
  padding-bottom: 8px;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
}

.comment-author {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  margin-bottom: 10px;

  a {
    font-size: 16px;
    color: #000;
    font-weight: 600;
  }
}

.comment-content {
  font-size: 16px;
  line-height: 20px;
  color: #000;
  margin-bottom: 12px;
}

.comment-meta {
  display: flex;
  justify-content: flex-end;
}

.reply {
  a {
    font-size: 16px;
    color: #000;
    transition: .3s;
    position: relative;
    margin-right: 79px;

    &::before {
      content: '';
      position: absolute;
      width: 11px;
      height: 16px;
      background-image: url(../img/icons/reply.png);
      background-repeat: no-repeat;
      top: 50%;
      transform: translateY(-50%);
      left: -18px;
    }

    &:hover {
      color: #000;
    }
  }
}

.comment-metadata {
  time {
    font-size: 16px;
    color: #000;
  }
}

.comments-title {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 25px;
  text-transform: uppercase;
}

.comment-notes {
  display: none;
}

.comment-reply-title {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  margin-bottom: 20px;
}

.comment__form {
  padding-top: 90px;
  padding-bottom: 39px;
  label {
    display: none;
  }

  form {
    display: block;
    width: 100%;

    input {
      width: 100%;
      display: block;
      height: 41px;
      background-color: #ebebeb;
      margin-bottom: 19px;
      padding-left: 26px;
      font-size: 16px;
      color: #000;
      padding-right: 10px;
    }

    textarea {
      height: 92px;
      width: 100%;
      display: block;
      padding-left: 26px;
      font-size: 16px;
      color: #000;
      background-color: #ebebeb;
      padding-top: 5px;
      padding-right: 10px;
    }
  }
  .submit {
    background-color: transparent;
    height: auto;
    line-height: 1;
    color: #00b6b4;
    text-decoration: underline !important;
    font-size: 20px;
    margin-bottom: 0;
    margin-top: 15px;
  }
}

.comment-form-cookies-consent {
  display: none;
}

.comment-reply-title {
  font-size: 16px;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
}

.comment-respond {
  width: 295px;
  margin: 0 auto;
}

.comment__section {
  padding-top: 20px;
}


@media screen and (max-width: 760px) {
  .comment__section {
    display: none;
  }
}