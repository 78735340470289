.section__useful {
  padding-top: 50px;
  padding-bottom: 34px;
  .tour__tabs {
    margin-top: 55px;

    li {
      margin-left: 40px;
      margin-right: 40px;
    }
  }
}

.useful__title {
  text-transform: uppercase;
  text-align: center;
  font-size: 25px;
  color: #000;
  font-weight: 600;
  margin-top: 52px;
}

.section__useful__content {
  .tour__tabs-target {
    width: 100%;
    padding-right: 0;
  }
}

.useful__article {
  padding-top: 53px;
  display: flex;
  padding-left: 70px;
  padding-right: 100px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: stretch;

  .entry-content {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    align-content: center;
  }

  .wp-block-image {
    max-width: 560px;
    display: inline-block;
  }

  .left-p {
    padding-top: 0;
    width: calc(100% - 570px);
    padding-left: 20px;
    height: 100%;
  }

  p {
    color: #333232;
    font-size: 18px;
    line-height: 30px;
    padding-bottom: 20px;
    padding-top: 20px;
    display: inline-block;
    width: 100%;

    &:first-child {
      padding-top: 0;
    }
  }
  figure {
    margin: 0;

    img {
      width: auto;
      max-width: 100%;
    }
  }
  blockquote {
    position: relative;
    margin: 0;
    background-color: #e0f5f5;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%;

    p {
      padding-bottom: 0;
      padding-top: 0;
    }

    &::before {
      content: '';
      position: absolute;
      width: 50vw;
      left: -50vw;
      top: 0;
      bottom: 0;
      background-color: #e0f5f5;
      background-image: url(../img/icons/quote.png);
      background-repeat: no-repeat;
      background-position: calc(100% - 34px) 22px;
    }
    &::after {
      content: '';
      position: absolute;
      width: 50vw;
      right: -50vw;
      top: 0;
      bottom: 0;
      background-color: #e0f5f5;
    }
  }

  ul {
    padding-top: 46px;
    padding-bottom: 46px;
    width: 100%;
    li {
      color: #000000;
      font-size: 18px;
      position: relative;
      padding-left: 32px;
      line-height: 26px;
      margin-bottom: 38px;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        content: '';
        position: absolute;
        width: 23px;
        height: 26px;
        background-image: url(../img/icons/item.png);
        background-repeat: no-repeat;
        top: 50%;
        transform: translateY(-50%);
        left: -26px;
      }
    }
  }

  h1, h2, h3, h4, h5 {
    margin: 0;
    padding: 0;
    font-size: 20px !important;
    font-weight: 600;
    padding-top: 10px;
    width: 100%;
  }

  em {
    position: relative;
    width: 100%;

    &::before {
      content: '';
      position: absolute;
      width: 16px;
      top: 0;
      bottom: 0;
      left: -30px;
      background-color: #ff8800;
    }
  }

  .wp-block-media-text {
    display: inline-block;
    position: relative;
    margin-top: 1px;
    margin-bottom: 1px;
    p {
      padding: 0 !important;
      margin: 0;
      font-size: 20px !important;
      font-weight: 600;
      color: #000;
      line-height: 46px;
    }
    div {
      padding: 0 !important;
      margin: 0 !important;
    }
    img {
      position: absolute;
      top: 50%;
      width: auto;
      transform: translateY(-50%);
      right: -137px;
      max-height: 46px;
    }
  }

  .wp-block-columns {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    p {
      padding: 0 !important;
    }
  }
  .has-2-columns {
    .wp-block-column {
      width: calc(50% - 40px);
    }
  }

  .colored {
    position: relative;
    background-color: #e0f5f5;

    &::before {
      content: '';
      position: absolute;
      width: 50vw;
      left: -50vw;
      top: 0;
      bottom: 0;
      background-color: #e0f5f5;
    }
    &::after {
      content: '';
      position: absolute;
      width: 50vw;
      right: -50vw;
      top: 0;
      bottom: 0;
      background-color: #e0f5f5;
    }
  }

  .right-list {
    padding-top: 0;
    width: calc(100% - 620px);
    padding-left: 30px;
    display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;

    li {
      width: 100%;
    }
  }
}


@media screen and (max-width: 1160px) {
  .useful__article {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 980px) {
  .section__useful {
    padding-top: 0;
  }
  .section__useful__content {
    font-size: 16px;
    padding-bottom: 20px;
    p {
      font-size: 16px;
      line-height: 20px;
    }
    blockquote {
      padding-left: 48px;
      &::before {
        left: calc(-50vw + 29px);
        background-position: 100% 18px;
        -webkit-background-size: 29px;
        background-size: 29px;
      }
    }

    ul {
      padding-top: 19px;
      padding-bottom: 36px;
      padding-left: 0 !important;
      li {
        font-size: 16px;
        padding-left: 72px;
        line-height: 26px;
        &::before {
          left: 8px;
        }
      }
    }
    em {
      padding-left: 12px;
      display: block;
      &::before {
        left: -10px;
      }
    }
    .wp-block-media-text {
      img {
        right: -72px;
        max-width: 60px;
      }
    }
    .has-2-columns {
      .wp-block-column {
        width: calc(50% - 10px);
      }
    }

    .left-p {
      padding-top: 20px;
      width: 100%;
      padding-left: 0;
      height: auto;
    }
    .right-list {
      padding-top: 20px;
      width: 100%;
      display: block;
    }
  }
}

@media screen and (max-width: 760px) {
  .section__useful {
    .tour__tabs {
      display: block;
      border: none;
      margin-top: 35px;

      li {
        margin: 0!important;
      }

      a {
        line-height: 50px;
        padding: 0 !important;
      }
    }
  }
  .useful__article {
    padding-top: 35px;
  }
}