.section__about {
  padding-top: 66px;
  padding-bottom: 36px;
}

.section__about__content {
  padding-left: 26px;
  padding-right: 26px;
  justify-content: space-between;
  padding-top: 47px;
}

.about__text {
  width: 505px;
  color: #333232;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 66px;

  p {
    margin-bottom: 31px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.about__photo {
  width: 535px;

  img {
    width: 100%;
  }
}

.about__photo-first {
  width: 577px;
  margin-bottom: 66px;
}

@media screen and (max-width: 1200px) {
  .section__about__content {
    padding-left: 15px;
    padding-right: 15px;
  }

  .about__photo {
    width: calc(50% - 15px);
  }
  .about__photo-first {
    width: calc(100% - 520px);
  }
}

@media screen and (max-width: 900px) {
  .about__text {
    width: 100%;
    order: 2;
    font-size: 16px;
    margin-bottom: 45px;
  }
  .about__photo {
    order: 3;
  }
  .about__photo-first {
    order: 1;
    margin-bottom: 45px;
    width: 100%;
    display: flex;
    justify-content: center;

    img {
      max-width: 500px;
    }
  }
}


@media screen and (max-width: 510px) {
  .section__about {
    padding-top: 0;
  }
  .about__photo {
    width: 100% !important;
    margin-bottom: 34px;

    &:last-child {
      margin-bottom: 0;
    }

    img {
      width: 100% !important;
    }
  }
}