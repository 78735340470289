.section__subscribe {
  background-color: #00b6b4;
  padding-bottom: 32px;
  position: relative;

  .container {
    position: relative;
    padding-top: 23px;
  }
}

.subscribe__title {
  text-align: center;
  font-size: 25px;
  color: #fff;
  letter-spacing: 1px;
}

.subscribe__form {
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 45px;
}

.input__subscribe {
  height: 41px;
  width: 295px;

  &:first-child {
    margin-right: 27px;
  }
}

.submit__subscribe {
  margin-left: 33px;
}

@media screen and (max-width: 1200px) {
  .section__subscribe {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 810px) {
  .submit__subscribe {
    margin-right: 33px;
    margin-top: 20px;
  }
}

@media screen and (max-width: 650px) {
  .subscribe__form {
    margin-top: 30px;
  }
  .input__subscribe {
    width: 100%;
    margin-right: 0 !important;
    margin-bottom: 20px;
  }
  .submit__subscribe {
    margin-top: 10px;
  }
}

@media screen and (max-width: 500px) {
  .section__subscribe {
    padding-bottom: 10px;
    .container {
      padding-top: 16px;
    }
  }
  .subscribe__title {
    font-size: 16px;
  }
  .subscribe__form {
    margin-top: 14px;
  }
  .input__subscribe {
    margin-bottom: 10px;
  }
  .submit__subscribe {
    margin-top: 2px;
  }
}