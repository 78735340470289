.header {
  background-color: #00b6b4;
  padding-top: 27px;
  padding-bottom: 27px;
}

.header__content {
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.header__nav {
  li {
    margin-right: 97px;

    &:last-child {
      margin-right: 0;
    }
  }
  a {
    color: #fff;
    font-size: 20px;
    transition: .3s;

    &:hover {
      color: #424141;
    }
  }
}

.header__right {
  align-items: center;
  align-content: center;
}

.header__socials {
  align-items: center;
  align-content: center;
  width: 170px;
  justify-content: space-between;

  a {
    &:hover {
      img {
        -webkit-filter: invert(60%);
        filter: invert(60%);
      }
    }
  }

  img {
    max-width: 51px;
    transition: .3s;
  }
}

.header__search__container {
  position: relative;
  margin-left: 52px;
}

.search__input {
  width: 243px;
  height: 41px;
  background-color: #fff;
  padding-left: 50px;
  padding-right: 10px;
  font-size: 14px;
  color: #000;
}

input[type="search"]::-webkit-input-placeholder { color: #000; }
input[type="search"]:-ms-input-placeholder { color: #000; }
input[type="search"]::-ms-input-placeholder { color: #000; }
input[type="search"]::placeholder { color: #000; }


.search__submit {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 48px;
  background-color: #fff;
  cursor: pointer;
  background-image: url(../img/icons/loup.png);
  background-repeat: no-repeat;
  background-position: center center;
}

.hamburger {
  display: none;
}

.mobile__nav {
  display: none;
}

.mobil__overlay {
  display: none;
}



@media screen and (max-width: 1200px) {
  .header {
    padding-left: 15px;
    padding-right: 15px;
  }
  .header__nav li {
    margin-right: 40px;
  }
}

@media screen and (max-width: 900px) {
  .header__socials {
    display: none;
  }
  .header__search__container {
    margin-left: 0;
  }
}

@media screen and (max-width: 670px) {
  .header__nav {
    display: none;
  }
  .hamburger {
    display: block;
    width: 74px;

    span {
      height: 10px;
      background-color: #fff;
      border-radius: 5px;
      margin-bottom: 8px;
      display: block;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .mobile__nav {
    position: absolute;
    top: 90px;
    left: 15px;
    z-index: 20;

    li {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        width: 270px;
        height: 55px;
        border: 1px solid #c2c2c2;
        padding-left: 14px;
        line-height: 53px;
        display: block;
        background-color: #fff;
        color: #424242;
        font-size: 27px;
      }
    }
  }
  .mobil__overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    opacity: .6;
    z-index: 15;
  }
}

@media screen and (max-width: 500px) {
  .header {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .hamburger {
    width: 60px;

    span {
      height: 8px;
      border-radius: 4px;
      margin-bottom: 6px;
    }
  }
  .search__input {
    width: 200px;
  }
}
