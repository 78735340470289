.section__blog {
  padding-bottom: 46px;
}

.blog__top {
  justify-content: space-between;
  padding-right: 20px;
}

.tegs__cloud {
  width: 329px;
  background-color: #ffffff;
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 22px;
}

.tegs__cloud__title {
  width: 100%;
  text-align: center;
  color: #000;
  font-size: 20px;
}

.tegs__links {
  text-align: center;
}

.tegs__links {
  justify-content: center;
  margin-top: 8px;
  a {
    color: #000;
    font-size: 18px;
    line-height: 20px;
    transition: .3s;

    &:hover {
      color: #807c7c;
    }
  }
}

.blog__content {
  padding-top: 52px;
}

.blog__item {
  justify-content: flex-end;
  margin-bottom: 70px;

  &:last-child {
    margin-bottom: 0;
  }
}

.blog__item__body {
  width: 485px;
  height: 485px;
  position: relative;
}

.blog__item__photo {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.blog__item__info {
  position: absolute;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
  width: 351px;
  height: 456px;
  left: -307px;
  top: 21px;
  padding-top: 34px;
  padding-left: 14px;
  padding-right: 14px;
  align-content: space-between;

  padding-bottom: 20px;
}

.blog__item__title {
  color: #000;
  font-size: 25px;
  text-align: center;
  line-height: 26px;
}

.blog__item__text {
  color: #000;
  font-size: 18px;
  line-height: 25px;
  height: 125px;
  overflow: hidden;
  padding-right: 10px;
  margin-top: 35px;
}

.blog__item__bottom {
  width: 100%;
}

.blog__item__tegs {
  a {
    color: #000;
    font-size: 18px;
    transition: .3s;

    &:hover {
      color: #807c7c;
    }
  }
}

.blog__item__view {
  align-items: center;
  align-content: center;
  width: 100%;
  color: #000;
  font-size: 15px;
  margin-top: 21px;

  img {
    margin-right: 6px;
  }
}

.blog__item__foot {
  justify-content: space-between;
  margin-top: 11px;
  align-items: center;
  align-content: center;
  width: 100%;
}

.blog__item__date {
  font-size: 15px;
  color: #000;
}

.blog__pageinatin {
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 52px;
}

.blog__pages {
  font-size: 20px;
  margin-left: 15px;
  margin-right: 15px;

  a {
    margin-left: 6px;
    margin-right: 6px;
    color: #000;
  }
}



@media screen and (max-width: 1200px) {
  .blog__top {
    padding-right: 15px;
  }
  .blog__content {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media screen and (max-width: 1024px) {
  .pages__tree {
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 824px) {
  .blog__item__info {
    left: -245px;
  }
}

@media screen and (max-width: 760px) {
  .section__blog {
    padding-bottom: 34px;
  }
  .blog__top {
    padding-left: 15px;
  }
  .other__pages {
    .section__main__content  {
      padding-bottom: 40px;
    }
  }
  .page__title {
    margin-right: 20px;
    margin-bottom: 15px;
  }
  .blog__content  {
    padding-top: 46px;
  }
  .blog__item  {
    justify-content: center;
    margin-bottom: 427px !important;
  }
  .blog__item__body {
    width: 421px;
    height: 421px;
    position: relative;
  }
  .blog__item__info {
    left: 40px;
    right: 40px;
    top: 360px;
    width: auto;
  }
}


@media screen and (max-width: 450px) {
  .tegs__cloud {
    width: 290px;
  }
  .blog__item {
    margin-bottom: 280px !important;
  }
  .blog__item__body {
    width: 100%;
    height: 380px;
  }
  .page__title {
    margin-right: 0;
  }
  .blog__top {
    padding-right: 0;
  }
  .blog__item__info {
    top: 320px;
    left: 15px;
    right: 15px;
  }
  .blog__item__info {
    padding: 15px !important;
  }
  .blog__item__title {
    font-size: 20px;
  }
  .blog__item__text {
    font-size: 15px;
    line-height: 18px;
    height: 90px;
    margin-top: 15px;
  }
  .blog__item__info {
    height: 300px;
  }
  .blog__item__tegs a {
    font-size: 16px;
  }
  .blog__item__view {
    margin-top: 15px;
  }
}