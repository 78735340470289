/* font-family: "MullerRegular"; */
@font-face {
  font-family: "MullerRegular";
  font-display: swap;
  src: url("../fonts/MullerRegular/MullerRegular.eot");
  src: url("../fonts/MullerRegular/MullerRegular.eot?#iefix")format("embedded-opentype"),
  url("../fonts/MullerRegular/MullerRegular.woff") format("woff"),
  url("../fonts/MullerRegular/MullerRegular.ttf") format("truetype"),
  url('../fonts/MullerRegular/MullerRegular.svg#Akrobat') format('svg');
  font-style: normal;
  font-weight: normal;
}


/* font-family: "MontserratRegular"; */
@font-face {
  font-family: "MontserratRegular";
  font-display: swap;
  src: url("../fonts/MontserratRegular/MontserratRegular.eot");
  src: url("../fonts/MontserratRegular/MontserratRegular.eot?#iefix")format("embedded-opentype"),
  url("../fonts/MontserratRegular/MontserratRegular.woff") format("woff"),
  url("../fonts/MontserratRegular/MontserratRegular.ttf") format("truetype"),
  url('../fonts/MontserratRegular/MontserratRegular.svg#Akrobat') format('svg');
  font-style: normal;
  font-weight: normal;
}


/* font-family: "MuseoSansCyrl900"; */
@font-face {
  font-family: "MuseoSansCyrl900";
  font-display: swap;
  src: url("../fonts/MuseoSansCyrl900/MuseoSansCyrl900.eot");
  src: url("../fonts/MuseoSansCyrl900/MuseoSansCyrl900.eot?#iefix")format("embedded-opentype"),
  url("../fonts/MuseoSansCyrl900/MuseoSansCyrl900.woff") format("woff"),
  url("../fonts/MuseoSansCyrl900/MuseoSansCyrl900.ttf") format("truetype"),
  url('../fonts/MuseoSansCyrl900/MuseoSansCyrl900.svg#Akrobat') format('svg');
  font-style: normal;
  font-weight: normal;
}