.tours__page {
  .section__subscribe {
    .top__page {
      display: block;
      top: -80px;
    }
  }
}

.section__tours {
  padding-top: 66px;
  padding-bottom: 36px;

  .page__title {
    padding-right: 15px;
  }
  .blog__top {
    padding-right: 0;
  }
  .blog__pageinatin {
    margin-top: 140px;
  }
}

.tours__list {
  margin-top: 92px;
  padding-left: 150px;
  padding-right: 15px;
}

.tours__sort {
  padding-right: 15px;
  .input__selsct {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.input__selsct {
  position: relative;
  width: 177px;
  height: 41px;
  padding-left: 0;

  img {
    position: absolute;
    top: 16px;
    right: 9px;
  }
}

.openedSelect {
  img {
    transform: rotate(-180deg);
  }
}

.input__options {
  position: absolute;
  top: 49px;
  left: 0;
  right: 0;
  z-index: 20;
  display: none;
}

.input__option {
  min-height: 41px;
  font-size: 16px;
  color: #010101;
  border: 1px solid #959595;
  padding-left: 24px;
  cursor: pointer;
  margin-bottom: 6px;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  padding-top: 3px;
  padding-bottom: 3px;

  &:last-child {
    margin-bottom: 0;
  }

  &.active {
    background-color: #00b6b4;
    color: #fff;
  }
}

.input__select__text {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  height: 100%;
  padding-left: 24px;
  color: #000;
  font-size: 16px;
  position: relative;
  cursor: pointer;
  line-height: 1;
}

.openedSelect {
  .input__options {
    display: block;
  }
}


.tours__item {
  max-width: 918px;
  width: 100%;
  height: 456px;
  justify-content: space-between;
  margin-bottom: 52px;

  &:last-child {
    margin-bottom: 0;
  }
}

.tours__item__left {
  width: calc(100% - 351px);
}

.tours__item__img {
  height: 370px;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.tours__item__buttons {
  justify-content: space-between;
  margin-top: 2px;
}

.tours__item__price {
  width: calc(59% - 4px);
  height: 84px;
  background-color: #00b6b4;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-size: 25px;
  color: #fff;
}

.tours__item__person {
  width: calc(41% - 4px);
  height: 84px;
  background-color: #ff8800;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-size: 25px;
  color: #fff;
}

.tours__item__right {
  background-color: #fff;
  width: 351px;
}

.tours__item__right {
  padding-left: 20px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 20px;
  align-content: space-between;
}

.tours__item__name {
  width: 100%;
  text-align: center;
  font-size: 25px;
  line-height: 26px;
  text-transform: uppercase;
}

.tours__item__text {
  width: 100%;
  padding-left: 15px;
  max-height: 220px;
  overflow: hidden;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
}

.tours__item__bottom {
  width: 100%;
  justify-content: space-between;
  align-content: flex-end;
  align-items: flex-end;
}

.country__marker {
  align-content: flex-end;
  align-items: flex-end;
}

.country__marker {
  font-size: 16px;
  color: #010101;

  img {
    margin-right: 5px;
  }
}

@media screen and (max-width: 1024px) {
  .tours__list {
    padding-left: 15px;
    justify-content: center;
  }
  .section__tours {
    padding-top: 0;
  }
  .tours__list {
    margin-top: 50px;
  }
}

@media screen and (max-width: 760px) {
  .tours__sort {
    width: 100%;
    margin-top: 35px;
  }
  .section__tours {
    padding-bottom: 34px;
  }
  .tours__item {
    height: 298px;
    position: relative;
    margin-bottom: 129px;
  }
  .tours__item__left {
    height: 100%;
    width: calc(50% - 10px);
  }
  .tours__item__img {
    height: 100%;
  }
  .tours__item__right {
    width: calc(50% + 10px);
    padding-left: 6px;
    padding-right: 18px;
    padding-top: 18px;
    padding-bottom: 15px;
    .read__more {
      font-size: 12px;
      img {
        height: 9px;
      }
    }
  }
  .tours__item__name {
    font-size: 16px;
    line-height: 17px;
  }
  .tours__item__text {
    max-height: 150px;
    font-size: 12px;
    line-height: 13px;
  }
  .tours__item__buttons {
    position: absolute;
    bottom: -103px;
    left: 0;
    right: 0;
  }
  .tours__item__price {
    height: 85px;
  }
  .tours__item__person {
    height: 85px;
  }
  .country__marker {
    font-size: 11px;
    img {
      height: 21px;
    }
  }
  .section__subscribe {
    .top__page {
      top: -76px;
      img {
        width: 34px;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .input__selsct {
    width: 100%;
    margin-bottom: 25px;
    margin-right: 0 !important;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .tours__item {
    height: auto;
    margin-bottom: 94px;
  }
  .tours__item__left {
    height: 150px;
    width: 100%;
  }
  .tours__item__img {
    height: 100%;
  }
  .tours__item__right {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .tours__item__text {
    max-height: inherit;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-left: 0;
  }
  .tours__item__buttons {
    position: absolute;
    bottom: -65px;
    left: 0;
    right: 0;
  }
  .tours__item__price {
    height: 55px;
    font-size: 20px;
  }
  .tours__item__person {
    height: 55px;
    font-size: 20px;
  }
}