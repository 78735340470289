.section__last {
  padding-top: 35px;
  padding-bottom: 137px;
  position: relative;

  .arrow__down {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 53px;
  }
}

.section__last__content {
  padding-left: 42px;
  padding-right: 42px;
}

.last__list {
  justify-content: space-between;
  margin-top: 45px;
}

.last__item {
  width: 319px;
  height: 319px;
  position: relative;
  margin-bottom: 53px;

  &:hover {
    .last__item__photo {
      transform: scale(1.06);
    }
  }

  .read__more {
    position: absolute;
    right: 28px;
    bottom: 10px;
  }
}

.last__item__photo {
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  transition: .3s;
  overflow: hidden;
}

.last__item__content {
  position: absolute;
  left: 14px;
  right: 14px;
  height: 157px;
  background-color: #fff;
  z-index: 10;
  bottom: -53px;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
  padding-left: 28px;
  padding-right: 28px;
  padding-top: 7px;
}

.last__item__title {
  text-align: center;
  font-size: 20px;
  color: #000;
  font-weight: 600;
  line-height: 1;
  max-height: 40px;
  margin-bottom: 12px;
}

.last__item__text {
  font-size: 16px;
  line-height: 20px;
  color: #000;
  max-height: 60px;
}



@media screen and (max-width: 1200px) {
  .section__last__content {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 1023px) {
  .section__last {
    padding-bottom: 63px;
  }
  .last__list {
    justify-content: center;
  }
  .last__item {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 93px;
  }
  .section__last .arrow__down {
    bottom: 28px;
  }
}

@media screen and (max-width: 500px) {
  .section__last {
    padding-top: 42px;
  }
  .last__list {
    margin-top: 26px;
    margin-bottom: 26px;
  }
  .last__item {
    margin-bottom: 73px;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 359px) {
  .last__item__content {
    padding-left: 10px;
    padding-right: 10px;
  }
  .last__item .read__more {
    right: 10px;
  }
}

