html,
body {
  padding: 0;
  margin: 0;
  position: relative;
}
body {
  overflow-x: hidden;
  height: 100%;
  font-family: 'Jura', sans-serif;
}

* {
  box-sizing: border-box; }

a {
  text-decoration: none !important;
}

ul {
  margin: 0;
  padding: 0; }

h1, h2, h3 {
  margin: 0;
  padding: 0;
  font-weight: normal; }

li {
  margin: 0;
  padding: 0;
  list-style: none; }

p {
  margin: 0;
}

input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

select {
  outline: none;
  border: none;
}

.clearfix::after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.wrapper {
  position: relative;
}

.container {
  max-width: 1170px;
  margin: 0 auto;
  font-family: "MullerRegular";
  color: #333232;
}

.hidden_photo {
  height: 10px;
  width: 10px;
  position: absolute;
  z-index: -10;
  opacity: 0;
}

.put_photo {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.cover {
  position: absolute;
  left:-10000%; right: -10000%;
  top: -10000%; bottom: -10000%;
  margin: auto auto;
  min-width: 1000%;
  min-height: 1000%;
  -webkit-transform:scale(0.1);
  transform: scale(0.1);
}

.section__title {
  width: 100%;

  &.bigBlie {
    font-size: 32px;
    color: #00b6b4;
    text-align: center;
    font-family: "MuseoSansCyrl900";
    letter-spacing: 3px;
    text-transform: uppercase;
  }
  &.firstLater {
    text-transform: uppercase;
    font-size: 25px;
    color: #1e1e1e;
    letter-spacing: 2px;
    text-align: left;
    &::first-letter {
      color: #ff7f00;
    }
  }
  &.center {
    text-align: center;
  }
}

.arrow__down {
  margin: 0 auto;
  display: inline-block;

  &:hover {
    img {
      width: 85px;
    }
  }

  img {
    width: 77px;
    transition: .3s;
  }
}

.read__more {
  color: #000;
  font-size: 16px;
  align-items: center;
  align-content: center;
  transition: .3s;

  &:hover {
    color: #807c7c;
  }

  img {
    margin-left: 5px;
  }
}


.input {
  background-color: rgb(255, 255, 255);
  padding-left: 14px;
  padding-right: 10px;
  color: #000;
  font-size: 16px;
}

input[type="text"]::-webkit-input-placeholder { color: #000; }
input[type="text"]:-ms-input-placeholder { color: #000; }
input[type="text"]::-ms-input-placeholder { color: #000; }
input[type="text"]::placeholder { color: #000; }

input[type="email"]::-webkit-input-placeholder { color: #000; }
input[type="email"]:-ms-input-placeholder { color: #000; }
input[type="email"]::-ms-input-placeholder { color: #000; }
input[type="email"]::placeholder { color: #000; }

input[type="tel"]::-webkit-input-placeholder { color: #000; }
input[type="tel"]:-ms-input-placeholder { color: #000; }
input[type="tel"]::-ms-input-placeholder { color: #000; }
input[type="tel"]::placeholder { color: #000; }

.form__submit {
  padding: 0;
  background-color: transparent;
  color: #fff;
  font-size: 20px;
  padding-right: 10px;
  background-image: url(../img/icons/arrow_white.png);
  background-repeat: no-repeat;
  background-position: center right;
  padding-bottom: 3px;
  cursor: pointer;
  transition: .3s;

  &:hover {
    color: #312f2f;
    background-image: url(../img/icons/arrow_black.png);
  }
}

.textarea {
  background-color: #fff;
  padding-left: 14px;
  padding-right: 10px;
  color: #000;
  font-size: 16px;
  padding-top: 7px;
}



label {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}



input, select, textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none !important;
  background-clip: padding-box;
  outline: none;
}

input[type="text"], input[type="email"], input[type="search"], input[type="password"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none !important;
  background-clip: padding-box;
  outline: none
}


.other__pages {
  background-color: #e0f5f5;
  .section__subscribe {
    margin-bottom: 42px;
  }
  .top__page {
    display: none;
  }
  .section__main {
    background-image: none;
    height: auto;
    background-color: #e0f5f5;
  }
  .section__main__nav {
    background-image: none;
  }
  .section__main__nav a {
    color: #000000;
  }
  .section__main__content {
    padding-top: 21px;
    padding-bottom: 55px;
  }
  .section__main__nav {
    margin-top: 30px;
  }
}

.page__title {
  width: 302px;
  height: 51px;
  line-height: 53px;
  text-align: center;
  background-image: url(../img/decor/title_bg.png);
  background-repeat: no-repeat;
  background-position: center center;
  color: #fff;
  font-size: 25px;
  text-transform: uppercase;
  font-family: "MuseoSansCyrl900";
  letter-spacing: 2px;
}

.section__subscribe {
  .top__page {
    display: none;
  }
}