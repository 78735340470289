.section__instagramm {
  padding-top: 50px;
  padding-bottom: 27px;
}

.instagramm__list {
  padding-left: 70px;
  padding-right: 70px;
  justify-content: space-between;
  margin-top: 50px;
}

.instagramm__item {
  width: 243px;
  height: 249px;
}

.instagramm__link {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.instagramm__subscribe {
  justify-content: center;
  width: 100%;
  margin-top: 27px;
}

.instagramm__subscribe {
  color: #000;
  font-size: 20px;
  align-items: center;
  align-content: center;
  transition: .3s;

  &:hover {
    color: #807c7c;
  }

  img {
    margin-left: 5px;
    padding-top: 3px;
  }
}


@media screen and (max-width: 1200px) {
  .instagramm__list {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 1024px) {
  .instagramm__item {
    width: calc(50vw - 30px);
    height: calc(50vw - 30px);
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 760px) {
  .section__instagramm {
    padding-top: 5px;
    padding-bottom: 45px;
  }
  .instagramm__list {
    margin-top: 28px;
  }
  .instagramm__subscribe {
    margin-top: 0;
  }
}

@media screen and (max-width: 500px) {
  .instagramm__list {
    justify-content: center;
  }
  .instagramm__item {
    width: 290px;
    height: 290px;
    margin-bottom: 12px;
  }
  .instagramm__subscribe {
    margin-top: 15px;
  }
}